import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, SEO } from '../components';
import { Link } from 'gatsby';
import { clearCart } from '@src/store/cart';
import { getPaymentIntent, sendEmail } from '../utils/mmc-api/api';
import CompletePurchaseEmail from '../components/Emails/CompletePurchaseEmail';
import Spinner from '../components/UI/Spinner';

const Success = ({ location }) => {
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const [cart, setCart] = useState('');
  const [getCart, setGetCart] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pid, setPid] = useState();
  let paymentIntentId;
  let module;
  let title;
  let category;
  let subTitle;

  const search = location.search.substring(1);
  const params = search.split('&');
  let obj = {};
  let tempCart = {};
  for (let i = 0; i < params.length; i++) {
    let split = params[i].split('=');
    obj[split[0]?.trim()] = split[1]?.trim();
  }

  const getPaymentIntentHandler = async () => {

    const response = await getPaymentIntent(obj.payment_intent);

    setPid(response);
  };


  if ('redirect_status' in obj && 'payment_intent' in obj) {
    if (obj.redirect_status === 'succeeded' && getCart) {
      setGetCart(false);
      getPaymentIntentHandler();
    }
  }
  useEffect(() => {

    if (pid?.length > 0) {
      (paymentIntentId = pid[0].id), (tempCart.cart = pid[0].cart);
      tempCart.id = pid[0].id;
      setCart(tempCart);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [pid]);

  const renderModuleTitle = (module, title) => {
    return (
      <div className={title ? 'border-t' : ''}>
        <div className={title ? 'success-cart-title' : 'success-cart-subtitle'}>{module}</div>
        <div></div>
        <div></div>
      </div>
    );
  };

  const url = process.env.GATSBY_BACKEND_URL + '/profile';

  let objToday = new Date(),
    weekday = new Array(
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ),
    dayOfWeek = weekday[objToday.getDay()],
    domEnder = (function () {
      let a = objToday;
      if (/1/.test(parseInt((a + '').charAt(0)))) return 'th';
      a = parseInt((a + '').charAt(1));
      return 1 == a ? 'st' : 2 == a ? 'nd' : 3 == a ? 'rd' : 'th';
    })(),
    dayOfMonth = objToday.getDate() < 10 ? '0' + objToday.getDate() : objToday.getDate(),
    months = new Array(
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ),
    curMonth = months[objToday.getMonth()],
    curYear = objToday.getFullYear();
  let today = dayOfMonth + ' ' + curMonth + ', ' + curYear;

  const html = CompletePurchaseEmail({
    orderDate: today,
    orderNumber:
      'MMC-' +
      cart.id?.toLocaleString('en-US', {
        minimumIntegerDigits: 6,
        useGrouping: false,
      }),
    cart: cart,
    user: user
  });

  useEffect(() => {
    if (cart?.cart?.content?.length > 0 && user?.email) {
      const data = {
        userEmail: user.email,
        subject: 'Thank you for your purchase',
        cart: cart.cart.content,
        url: process.env.GATSBY_BACKEND_URL + '/profile',
        html: html.content,
      };

      sendEmail(data);

      dispatch(clearCart());
    }
  }, [cart, user]);

  return (
    <Layout>
      <SEO title="Success!" />
      {loading ? (
        <div className="container">
          <Spinner />
        </div>
      ) : (
        <article className="content-section">
          <div className="container">
            <div className="success-title-container">
              {/* <h1 className="success-h1">Success!</h1> */}
              <p className="paragraph">
                <span>Thank you for purchasing!</span>
                <br />
                <br />
                <span>Your purchased documents are available from your profile page </span>
                <Link to="/profile" className="success-link">
                  here
                </Link>
                .
              </p>
            </div>

            <div className="successContainer">
              <div className="w-full mb-3">
                <div>
                  {!!cart?.cart?.content?.length &&
                    cart?.cart?.content.map((item) => {
                      title = undefined;
                      subTitle = undefined;
                      if (module !== item.module.slug) {
                        category = undefined;
                        module = item.module.slug;
                        title = renderModuleTitle(item.module.title, true);
                      }

                      if (category !== item.documentCategory[0].name) {
                        category = item.documentCategory[0].name;
                        subTitle = renderModuleTitle(item.documentCategory[0].name, false);
                      }

                      return (
                        <div key={item.id}>
                          {title ? title : ''}
                          {subTitle ? subTitle : ''}
                          <div className="successDocumentContainer">
                            <div className="successCol1">{item.id}</div>
                            <div className="successCol2">{item.name}</div>
                            <div className="successCol3">£{item.price}.00</div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <p className="text-xl text-center mb-3">
                <span className="p-1 border-b font-bold">Total</span>
                <span className="p-1 border-b ">£{cart?.cart?.totalPrice}</span>
              </p>
            </div>
          </div>
          {/* {test()} */}
        </article>
      )}
    </Layout>
  );
};

export default Success;

//https://beta.dev.mymathscloud.com/post-checkout/?payment_intent=pi_3KjWJaIqOjsZqv7d0r8zwPPf&payment_intent_client_secret=pi_3KjWJaIqOjsZqv7d0r8zwPPf_secret_HzQXUNvEZq5PU8hQ03gJ5EazR&redirect_status=succeeded

import React from 'react';

const CompletePurchaseEmail = ({ cart, orderDate, orderNumber, user }) => {
  const url = 'https://mymathscloud.com/profile';
  let module;
  let title;
  let category;
  let subTitle;

  const renderModuleTitle = (module, title) => {

    title ? htmlContent += `
    <tr style="border-top-width: 1px; margin-top: 10px;">
        <th style="text-align: left; font-size: 20px; padding-top: 15px;">${module}</th>
        <th></th>
        <th></th>
      </tr>
    ` : htmlContent += `
    <tr>
        <th style="padding: 0 16px;">${module}</th>
        <th></th>
        <th></th>
      </tr>`
    return true;
  };

  let htmlContent = `
    <!DOCTYPE html>
<html
  lang="en"
  class="nomad"
  style="background-color: #f3f4f8; font-size: 0; line-height: 0"
>
  <head
    xmlns="http://www.w3.org/1999/xhtml"
    lang="en"
    xml:lang="en"
    style="font-family: Helvetica, Arial, sans-serif"
  >
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <title style="font-family: Helvetica, Arial, sans-serif">
      New Purchase Confirmation
    </title>

    <meta
      name="viewport"
      content="width=device-width"
      style="font-family: Helvetica, Arial, sans-serif"
    />
  </head>
  <body
    style="
      -moz-box-sizing: border-box;
      -ms-text-size-adjust: 100%;
      -webkit-box-sizing: border-box;
      -webkit-text-size-adjust: 100%;
      background-color: #f3f4f8;
      background-image: url('https://www.datocms-assets.com/34817/1648114430-large-2.jpg');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: cover;
      background-size: cover;
      box-sizing: border-box;
      color: #0a0a0a;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.43;
      margin: 0;
      min-width: 600px;
      padding: 0;
      text-align: left;
      width: 100% !important;
    "
  >
    <table
      width="600"
      cellpadding="0"
      cellspacing="0"
      style="
        border-collapse: collapse;
        border-spacing: 0;
        font-family: Helvetica, Arial, sans-serif;
        max-width: 600px;
        min-width: 600px;
        padding: 0;
        text-align: left;
        vertical-align: top;
        margin: 0 auto;
      "
    >
      <tr
        style="
          font-family: Helvetica, Arial, sans-serif;
          padding: 0;
          text-align: left;
          vertical-align: top;
        "
      >
        <td
          style="
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            border-collapse: collapse !important;
            color: #0a0a0a;
            font-family: Helvetica, Arial, sans-serif;
            font-size: 14px;
            font-weight: 400;
            hyphens: auto;
            line-height: 1.43;
            margin: 0;
            padding: 43px 0 0 0;
            text-align: left;
            vertical-align: top;
            word-wrap: break-word;
          "
        >
          <div
            style="
              
              font-family: Helvetica, Arial, sans-serif;
            "
          >
            <div
              style="
                font-family: Helvetica, Arial, sans-serif;
                height: 100%;
                min-height: 100px;
                padding: 0 40px;
              "
            >
              <table
                style="
                  border-collapse: collapse;
                  border-spacing: 0;
                  font-family: Helvetica, Arial, sans-serif;
                  padding: 0;
                  text-align: left;
                  vertical-align: top;
                  width: 100%;
                "
              >
                <tr
                  style="
                    font-family: Helvetica, Arial, sans-serif;
                    padding: 0;
                    text-align: left;
                    vertical-align: top;
                  "
                >
                  <td
                    style="
                      -moz-hyphens: auto;
                      -webkit-hyphens: auto;
                      border-collapse: collapse !important;
                      color: #0a0a0a;
                      font-family: Helvetica, Arial, sans-serif;
                      font-size: 14px;
                      font-weight: 400;
                      hyphens: auto;
                      line-height: 1.43;
                      margin: 0;
                      padding: 32px 0 0 0;
                      text-align: left;
                      vertical-align: top;
                      width: 50%;
                      word-wrap: break-word;
                    "
                  >
                  <a
                  href="https://mymathscloud.com"
                  class="nomad-btn"
                  target="_blank"
                  style="
                    
                    border: 1px solid #050038;
                    border-radius: 4px;
                    box-sizing: border-box;
                    color: #050038 !important;
                    cursor: pointer;
                    display: inline-block;
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 16px !important;
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: 400;
                    height: 48px;
                    letter-spacing: normal;
                    line-height: 48px !important;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    text-decoration: none;
                    white-space: nowrap;
                    width: 160px;
                  "
                >
                  <span style="font-family: Helvetica, Arial, sans-serif"
                    >Go to MyMathsCloud</span
                  >
                </a>
                  </td>
                  <td
                    style="
                      -moz-hyphens: auto;
                      -webkit-hyphens: auto;
                      border-collapse: collapse !important;
                      color: #0a0a0a;
                      font-family: Helvetica, Arial, sans-serif;
                      font-size: 14px;
                      font-weight: 400;
                      hyphens: auto;
                      line-height: 1.43;
                      margin: 0;
                      padding: 26px 0 0 0;
                      text-align: right;
                      vertical-align: top;
                      width: 50%;
                      word-wrap: break-word;
                    "
                  >
                    
                    <a
                      style="
                        color: #2a79ff;
                        font-family: Helvetica, Arial, sans-serif;
                        font-weight: 400;
                        line-height: 1.43;
                        margin: 0;
                        padding: 0;
                        text-align: left;
                        text-decoration: none;
                      "
                      href="https://mymathscloud.com"
                      target="_blank"
                    >
                      <img
                        src="https://www.datocms-assets.com/34817/1600883466-logo-small.svg"
                        style="
                          -ms-interpolation-mode: bicubic;
                          border: none;
                          clear: both;
                          display: block;
                          font-family: Helvetica, Arial, sans-serif;
                          height: 100px;
                          max-height: 100%;
                          max-width: 100%;
                          outline: 0;
                          text-decoration: none;
                          width: auto;
                          float: right;
                        "
                      />
                    </a>
                  </td>
                </tr>
              </table>
            </div>
            <div style="font-family: Helvetica, Arial, sans-serif">
              <div
                class="nomad-title-block"
                style="
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: 100% auto;
                  font-family: Helvetica, Arial, sans-serif;
                  padding: 40px 40px 36px;
                "
              ><div
              style="
                color: #050038;
                font-family: Helvetica, Arial, sans-serif;
                font-size: 20px !important;
                font-stretch: normal;
                font-style: normal;
                font-weight: 400;
                letter-spacing: normal;
                line-height: 1.2;
                margin-top: 16px;
                opacity: 0.6;
              "
            >
              Name: ${user?.fullname}
            </div>
            <div
                  style="
                    color: #050038;
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 20px !important;
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    line-height: 1.2;
                    margin-top: 16px;
                    opacity: 0.6;
                    display: flex;
                    flex-direction: row
                  "
                >
                  <div>Billing Address: </div>
                  <div style="display: flex; flex-direction: column; margin-left: 10px">
                    <div>${user?.address}</div>
                    <div>${user?.town}</div>
                    <div>${user?.postcode}</div>
                  </div>
                </div>
                <div
                  style="
                    color: #050038;
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 20px !important;
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    line-height: 1.2;
                    margin-top: 16px;
                    opacity: 0.6;
                  "
                >
                  Email: ${user?.email}
                </div>
                <div
                  style="
                    color: #050038;
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 20px !important;
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    line-height: 1.2;
                    margin-top: 16px;
                    opacity: 0.6;
                  "
                >
                  Order Number: ${orderNumber}
                </div>
                <div
                  style="
                    color: #050038;
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 20px !important;
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    line-height: 1.2;
                    margin-top: 16px;
                    opacity: 0.6;
                  "
                >
                  Order Date : ${orderDate}
                </div>
              </div>
              <div
                style="
                  font-family: Helvetica, Arial, sans-serif;
                  padding: 0 40px;
                "
              >
                <div
                  style="
                    
                    border-radius: 4px;
                    color: #050038;
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 24px;
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: 400;
                    min-height: 10px;
                    letter-spacing: normal;
                    line-height:35px;
                    text-align: center;
                    padding-top: 20px;
                  "
                >
                `;
  cart?.cart?.content?.map((doc) => {
    title = undefined;
    subTitle = undefined;
    if (module !== doc.module.slug) {
      category = undefined;
      module = doc.module.slug;
      renderModuleTitle(
        doc.module.title,
        true,
      );
    }

    if (category !== doc.documentCategory[0].name) {
      category = doc.documentCategory[0].name;
      renderModuleTitle(doc.documentCategory[0].name, false);
    }

    htmlContent += `
    <tr><td><span style="padding-left:40px">${doc.name}</span></td><td><span style="padding-left:20px">${
      doc.price ? '£' + doc.price + '.00' : 'Free'
    }</span></td></tr>`;
  });

  htmlContent += `
                <tr>
                <td>
                  <div style="font-size: 24px; font-weight: 700; margin-top: 50px;" >Total: </div>
                </td>
                <td>
                  <div style=" font-size: 24px; font-weight: 700; margin-top: 50px; padding-left: 20px;">
                  £${cart?.cart?.totalPrice}.00
                  </div> 
                </td>
              </tr>
              <table
                style="
                  border-collapse: collapse;
                  border-spacing: 0;
                  font-family: Helvetica, Arial, sans-serif;
                  padding: 0;
                  text-align: left;
                  vertical-align: top;
                  width: 100%;
                "
              >
                <tbody style="font-family: Helvetica, Arial, sans-serif">
                  <tr
                    style="
                      font-family: Helvetica, Arial, sans-serif;
                      padding: 0;
                      text-align: left;
                      vertical-align: top;
                    "
                  >
                    <td
                      height="0px"
                      style="
                        -moz-hyphens: auto;
                        -webkit-hyphens: auto;
                        border-collapse: collapse !important;
                        color: #0a0a0a;
                        font-family: Helvetica, Arial, sans-serif;
                        font-size: 0;
                        font-weight: 400;
                        hyphens: auto;
                        line-height: 0;
                        margin: 0;
                        mso-line-height-rule: exactly;
                        padding: 0;
                        text-align: left;
                        vertical-align: top;
                        word-wrap: break-word;
                      "
                    >
                      &nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                style="
                  border-collapse: collapse;
                  border-spacing: 0;
                  font-family: Helvetica, Arial, sans-serif;
                  padding: 0;
                  position: relative;
                  text-align: left;
                  vertical-align: top;
                  width: 100%;
                "
              >
                <tbody style="font-family: Helvetica, Arial, sans-serif">
                  <tr
                    style="
                      font-family: Helvetica, Arial, sans-serif;
                      padding: 0;
                      text-align: left;
                      vertical-align: top;
                    "
                  >
                    <th
                      style="
                        color: #0a0a0a;
                        font-family: Helvetica, Arial, sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 1.43;
                        margin: 0 auto;
                        padding: 0 40px;
                        text-align: left;
                        width: 440px;
                      "
                    >
                      <table
                        style="
                          border-collapse: collapse;
                          border-spacing: 0;
                          font-family: Helvetica, Arial, sans-serif;
                          padding: 0;
                          text-align: left;
                          vertical-align: top;
                          width: 100%;
                        "
                      >
                        <tr
                          style="
                            font-family: Helvetica, Arial, sans-serif;
                            padding: 0;
                            text-align: left;
                            vertical-align: top;
                          "
                        >
                          <th
                            style="
                              color: #0a0a0a;
                              font-family: Helvetica, Arial, sans-serif;
                              font-size: 14px;
                              font-weight: 400;
                              line-height: 1.43;
                              margin: 0;
                              padding: 0;
                              text-align: left;
                            "
                          >
                            
                          </th>
                          <th
                            style="
                              color: #0a0a0a;
                              font-family: Helvetica, Arial, sans-serif;
                              font-size: 14px;
                              font-weight: 400;
                              line-height: 1.43;
                              margin: 0;
                              padding: 0 !important;
                              text-align: left;
                              visibility: hidden;
                              width: 0;
                            "
                          ></th>
                        </tr>
                      </table>
                    </th>
                  </tr>
                </tbody>
              </table>
              <div
                style="
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: 100% auto;
                  font-family: Helvetica, Arial, sans-serif;
                  padding: 10px 0 23px 40px;
                "
              >
                <div
                  style="
                    color: #050038;
                    font-family: Helvetica, Arial, sans-serif;
                    font-size: 20px !important;
                    font-stretch: normal;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    line-height: 1.4;
                    margin-top: 16px;
                    opacity: 0.6;
                    text-align: center;
                  "
                >

                <a href="${url}" style="text-decoration:none; color:#050038;">
                    <span >To access your documents, please log in to your account.</span><span style="text-decoration: underline; color: blue"> Click here go to your profile page.</span>
                </a>
                </div>
              </div>
              <div
                style="

                  font-family: Helvetica, Arial, sans-serif;
                  height: 1px;
                "
              ></div>
            </div>
          </div>
          <div
            style="
              font-family: Helvetica, Arial, sans-serif;
              padding-bottom: 72px;
              padding-top: 42px;
            "
          >
            
          </div>
        </td>
      </tr>
    </table>
    <!-- prevent Gmail on iOS font size manipulation -->
    <div
      style="
        display: none;
        font: 15px courier;
        font-family: Helvetica, Arial, sans-serif;
        line-height: 0;
        white-space: nowrap;
      "
    >
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
    </div>
  </body>
</html>
    `;

  const response = {
    content: htmlContent,
  };

  return response;
};

export default CompletePurchaseEmail;
